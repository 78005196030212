.iv {
  /***** snap view css *****/
  /*** zoom slider ***/
  /**** snap view css end *****/
}
.iv-container {
  overflow: hidden;
  position: relative;
}
.iv-fullscreen {
  position: fixed;
  background: #0d0d0d;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  z-index: 1000;
}
.iv-fullscreen-container {
  position: relative;
  height: 100%;
  width: 100%;
}
.iv-container {
  overflow: hidden;
  position: relative;
}
.iv-image-mode {
  display: inline-block;
}
.iv-fullscreen-close {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  top: 10px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  text-shadow: 0px 0px 3px #6d6d6d;
  transition: all ease 200ms;
}
.iv-fullscreen-close:after, .iv-fullscreen-close:before {
  content: "";
  height: 4px;
  width: 24px;
  background: #FFF;
  position: absolute;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.iv-fullscreen-close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.iv-fullscreen-close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.iv-fullscreen-close:hover {
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}
.iv-snap-view {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 20px;
  left: 20px;
  border: 1px solid #aaa;
  background: black;
  z-index: 100;
  box-sizing: content-box;
  transition: opacity ease 400ms;
  opacity: 0;
}
.iv-snap-image-wrap {
  display: inline-block;
  position: absolute;
  max-width: 150px;
  max-height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.iv-snap-image {
  position: relative;
  -ms-touch-action: none;
      touch-action: none;
}
.iv-snap-handle {
  box-sizing: border-box;
  position: absolute;
  border: 1px solid white;
  transform: translate3d(0, 0, 0);
  box-shadow: 0px 0px 0px 200px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  cursor: grab;
}
.iv-snap-handle:active {
  cursor: grabbing;
}
.iv-zoom-slider {
  width: 100%;
  box-sizing: content-box;
  border: 1px solid #aaa;
  border-top: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 15px;
  position: absolute;
  top: 150px;
  left: -1px;
}
.iv-zoom-handle {
  width: 20px;
  height: 15px;
  background: white;
  position: absolute;
  cursor: pointer;
  cursor: grab;
}
.iv-zoom-handle:active {
  cursor: grabbing;
}
.iv-image-view {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.iv-image-wrap {
  display: inline-block;
}
.iv-image-wrap:active {
  cursor: move;
}
.iv-image {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  -ms-touch-action: none;
      touch-action: none;
  transform: translate3d(0, 0, 0);
}
.iv-loader {
  top: 50%;
  left: 50%;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  z-index: 100;
  margin-top: -16px;
  margin-left: -16px;
  font-size: 5px;
  position: absolute;
  text-indent: -9999em;
  border: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: loading-icon 1.1s infinite linear;
}
.iv-loader:after {
  width: 10em;
  height: 10em;
  border-radius: 50%;
}
@keyframes loading-icon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 767px) {
  .iv-snap-view {
    z-index: -1;
    visibility: hidden;
  }
}